<template>
    <a-drawer
        v-model:visible="visible"
        title="集成工单工时核对详情"
        @cancel="handleCancel"
        :destroyOnClose="true"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions :column="2">
            <a-descriptions-item label="工单号">
                {{ detailRow?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="工单状态">
                <span :style="{ color: getStatusColor(detailRow?.status) }">
                    {{ detailRow?.statusDesc }}
                </span>
            </a-descriptions-item>
            <a-descriptions-item label="投产时间">
                {{ detailRow?.actualStartTime }}
            </a-descriptions-item>
            <a-descriptions-item label="完工时间">
                {{ detailRow?.actualEndTime }}
            </a-descriptions-item>
            <a-descriptions-item label="项目名称">
                {{ detailRow?.projectName }}
            </a-descriptions-item>
            <a-descriptions-item label="项目编码">
                {{ detailRow?.projectCode }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
                {{ detailRow?.productName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品编码">
                {{ detailRow?.productCode }}
            </a-descriptions-item>
            <a-descriptions-item label="集成编号">
                {{ detailRow?.containerNo }}
            </a-descriptions-item>
            <a-descriptions-item label="生产班组">
                {{ detailRow?.produceTeamName }}
            </a-descriptions-item>
            <a-descriptions-item label="班组长">
                {{ detailRow?.produceTeamLeaderName }}
            </a-descriptions-item>
            <a-descriptions-item label="标准总工时">
                {{ detailRow?.totalStandardWorkingHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="实际总工时">
                {{ detailRow?.totalWorkingHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="异常总工时">
                {{ detailRow?.totalExceptionWorkingHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="额外总工时">
                {{ detailRow?.totalOtherWorkingHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="报工次数">
                {{ detailRow?.reportWorkCount }}
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiJCWorkReportDetail } from "@/api";
import Select from "@/components/Select";
import { formateMoment, getStatusColor } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
import { baseURL } from "@/utils/config";
export default defineComponent({
    props: ["detailRow", "status"],
    emits: ["eventUpdateOk", "update:detailRow"],
    components: {
        Select,
        DownOutlined,
        Upload,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:detailRow", null);
        };

        watch(
            () => props.detailRow,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            formateMoment,
            baseURL,
            getStatusColor,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    color: grey;
    > div {
        margin-top: 16px;
        display: flex;
        > div {
            flex: 1;
        }
    }
}
.members-box {
    display: flex;
    flex-wrap: wrap;
}
</style>
