<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="params.code" placeholder="请输入工单号" />
            <Select
                style="width: 153px"
                v-model:value="params.status"
                :options="[
                    { label: '待投产', value: 'WAIT_PRODUCING' },
                    { label: '生产中', value: 'PRODUCING' },
                    { label: '已完成', value: 'COMPLETED' },
                    { label: '已终止', value: 'TERMINATED' },
                ]"
                placeholder="请选择工单状态"
            />
            <a-input v-model:value="params.projectName" placeholder="请输入项目名称" />
            <a-input v-model:value="params.productName" placeholder="请输入产品名称" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Select from "@/components/Select";
export default defineComponent({
    emits: ["search"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            submitUserOptions: [],
            date: [],
            params: {
                code: undefined,
                status: undefined,
                projectName: undefined,
                productName: undefined,
            },
        });

        const reset = () => {
            state.params.code = undefined;
            state.params.status = undefined;
            state.params.projectName = undefined;
            state.params.productName = undefined;
            search();
        };

        const search = () => {
            state.params.code = state.params.code?.trim();
            state.params.projectName = state.params.projectName?.trim();
            state.params.productName = state.params.productName?.trim();
            emit("search", state.params);
        };

        search();

        return {
            ...toRefs(state),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
